import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Categories from "../Categories/Categories";
import { products } from "../data/Data";
import "./Product.css";

const Products = () => {
  console.log(products);


	const is_mobile_screen=window.matchMedia('(max-width:485px)').matches;
  return (
    <>
      
      { is_mobile_screen ?
      <img className="mobile_banner"
		height="100%"
		width="100%"
		src={process.env.PUBLIC_URL + "ecowares_banner3.png"}
		alt="banner"
	  />:
      <div className="banner2">
      <img 
		height="100%"
		width="100%"
		src={process.env.PUBLIC_URL + "ecowares_banner4.png"}
		alt="banner"
	  />
    </div>
}

      <Categories />
      <h1 className="product_heading ">Our Products</h1>
      <div className="product_container text-center pb-1">
        <div className="outer_div mx-5 px-5 ">
          <div className="row  justify-content-center">
            {products.map((p, i) => (
              <div className="col d-flex justify-content-center" key={i}>
                <Card 
                  className="card-container"
                  onClick={() => window.open(`${p.link}`, "_blank")}
                >
                  <Card.Img
                    variant="top"
                    src={p.image}
                    className="card-image"
                  />
                  <Card.Body>
                    <Card.Title className="card-title">
                      {p.product_name}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
          <div>
            <Button
              variant="success"
              size="lg"
              onClick={() =>
                window.open(
                  "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna",
                  "_blank"
                )
              }
              className="product_button"
            >
              Explore All
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
