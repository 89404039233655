import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
const Header = () => {
  return (
    <div>
      <Navbar
        sticky="top"
        expand="lg"
        className=" nav-background"
        shadow
        p-3
        mb-5
        bg-white
        rounded
      >
        <Container>
          <Navbar.Brand href="#home">
            <img src="ecowares_logo.png" alt="Logo" id="hero" />
            <span className="brand-text">ECO-Wares</span>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav navbar-expand-md">
            <Nav className="ms-auto">
              <Nav.Link
                href="/"
                // style={{ color: "orangered" }}
                className="background-change"
              >
                Home
              </Nav.Link>

              <Nav.Link
                // style={{ color: "orangered" }}
                className="background-change"
                href="/about"
              >
                About Us
              </Nav.Link>

              <NavDropdown
                title={
                  <span className=" my-auto" style={{ color: "orangered" }}>
                    Products
                  </span>
                }
                id="dropdown-basic"
                className="background-change size=lg"
              >
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-tees"
                  className="dropdown_menu"
                >
                  T-Shirts
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=w-dresses"
                  className="dropdown_menu"
                >
                  Dresses
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=all-hats"
                  className="dropdown_menu"
                >
                  Hats
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=all-totes"
                  className="dropdown_menu"
                >
                  Tote Bags
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-phone-cases"
                  className="dropdown_menu"
                >
                  Phone Cases
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-mugs"
                  className="dropdown_menu"
                >
                  Mugs
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-coasters"
                  className="dropdown_menu"
                >
                  Coasters
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-apron"
                  className="dropdown_menu"
                >
                  Aprons
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna"
                  className="dropdown_menu"
                >
                  Explore All
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
