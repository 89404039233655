
export const products = [
   {
   "id": 0,
   "product_name": "SAVE THE PLANET Classic T-Shirt",
   "image": "Products/p1.png",
   "price": "$22.88",
   "link": "https://www.redbubble.com/i/t-shirt/SAVE-THE-PLANET-by-AzarraAnna/162035889.WFLAH.XYZ"
   
   },
   
   
   {
   "id": 1,
   "product_name": "PEACE VIBES Fitted Scoop T-Shirt",
   "image": "Products/p2.png",
   "price": "$27.73",
   "link": "https://www.redbubble.com/i/t-shirt/PEACE-VIBES-by-AzarraAnna/161298090.PFN84"
   },
   
   {
   "id": 2,
   "product_name": "PLANETS in MOTION Essential T-Shirt",
   "image": "Products/p3.png",
   "price": "$22.88",
   "link": "https://www.redbubble.com/i/t-shirt/PLANETS-in-MOTION-by-AzarraAnna/161150516.NL9AC"
   },
   
   {
   "id": 3,
   "product_name": "ONE EARTH ONE HUMANITY Baseball ¾ Sleeve T-Shirt",
   "image": "Products/p4.png",
   "price": "$34.55",
   "link": "https://www.redbubble.com/i/t-shirt/ONE-EARTH-ONE-HUMANITY-by-AzarraAnna/161149983.TR8D9"
   },
   
   {
   "id": 4,
   "product_name": "COLOR BURST Coffee Mug",
   "image": "Products/p5.png",
   "price": "$17.95",
   "link": "https://www.redbubble.com/i/mug/COLOR-BURST-by-AzarraAnna/161368945.W3OIY"
   },
   
   {
   "id": 5,
   "product_name": "PRISM BEAUTY Coffee Mug",
   "image": "Products/p6.png",
   "price": "$17.95",
   "link": "https://www.redbubble.com/i/mug/PRISM-BEAUTY-by-AzarraAnna/161199897.W3OIY"
   },
   
   {
   "id": 6,
   "product_name": "COLOR SPIRALS Coffee Mug",
   "image": "Products/p7.png",
   "price": "$17.95",
   "link": "https://www.redbubble.com/i/mug/COLOR-SPIRALS-by-AzarraAnna/161368672.W3OIY"
   },
   
   {
   "id": 7,
   "product_name": "TIME TRAVEL Coffee Mug",
   "image": "Products/p8.png",
   "price": "$17.95",
   "link": "https://www.redbubble.com/i/mug/TIME-TRAVEL-by-AzarraAnna/161131355.W3OIY"
   },
   
   {
   "id": 8,
   "product_name": "EARTH..OUR ONLY HOME Cap",
   "image": "Products/p9.png",
   "price": "$23.89",
   "link": "https://www.redbubble.com/i/hat/EARTH-OUR-ONLY-HOME-by-AzarraAnna/161150292.NJ288"
   },
   
   {
   "id": 9,
   "product_name": "UNITY Cap",
   "image": "Products/p10.png",
   "price": "$23.89",
   "link": "https://www.redbubble.com/i/hat/UNITY-by-AzarraAnna/161297531.NJ288"
   },
   
   {
   "id": 10,
   "product_name": "GREEN EARTH..BETTER FUTURE Bucket Hat",
   "image": "Products/p11.png",
   "price": "$24.60",
   "link": "https://www.redbubble.com/i/bucket-hat/GREEN-EARTH-BETTER-FUTURE-by-AzarraAnna/161312912.51XZU"
   },
   
   {
   "id": 11,
   "product_name": "RADIANT COLORS Bucket Hat",
   "image": "Products/p12.png",
   "price": "$24.60",
   "link": "https://www.redbubble.com/i/bucket-hat/RADIANT-COLORS-by-AzarraAnna/161377939.51XZU"
   },
   
   {
   "id": 12,
   "product_name": "RADIANT COLORS iPhone Case",
   "image": "Products/p13.png",
   "price": "$33.34",
   "link": "https://www.redbubble.com/i/iphone-case/RADIANT-COLORS-by-AzarraAnna/161377939.TQTSI"
   },
   
   {
   "id": 13,
   "product_name": "PRISM BEAUTY iPhone Case",
   "image": "Products/p14.png",
   "price": "$33.34",
   "link": "https://www.redbubble.com/i/iphone-case/PRISM-BEAUTY-by-AzarraAnna/161199897.TQTSI"
   },
   
   {
   "id": 14,
   "product_name": "COLOR BURST Samsung Galaxy Phone Case",
   "image": "Products/p15.png",
   "price": "$21.53",
   "link": "https://www.redbubble.com/i/samsung-case/COLOR-BURST-by-AzarraAnna/161368945.BW6FM"
   },
   
   {
   "id": 15,
   "product_name": "SPACE VORTEX Samsung Galaxy Phone Case",
   "image": "Products/p16.png",
   "price": "$21.53",
   "link": "https://www.redbubble.com/i/samsung-case/SPACE-VORTEX-by-AzarraAnna/161228460.BW6FM"
   },
   
   {
   "id": 16,
   "product_name": "PLANET GUARDIAN Coasters (Set of 4)",
   "image": "Products/p17.png",
   "price": "$16.46",
   "link": "https://www.redbubble.com/i/coasters/PLANET-GUARDIAN-by-AzarraAnna/161197237.E5I3N"
   },
   
   {
   "id": 17,
   "product_name": "STARFIELD Coasters (Set of 4)",
   "image": "Products/p18.png",
   "price": "$16.46",
   "link": "https://www.redbubble.com/i/coasters/STARFIELD-by-AzarraAnna/161128546.E5I3N"
   },
   
   {
   "id": 18,
   "product_name": "CREATE BETTER WORLD Apron",
   "image": "Products/p19.png",
   "price": "$23.69",
   "link": "https://www.redbubble.com/i/apron/CREATE-BETTER-WORLD-by-AzarraAnna/161914768.6ZXWR"
   },
   
   {
   "id": 19,
   "product_name": "HARMONICS Apron",
   "image": "Products/p20.png",
   "price": "$23.69",
   "link": "https://www.redbubble.com/i/apron/HARMONICS-by-AzarraAnna/161129512.6ZXWR"
   },
   
   {
   "id": 20,
   "product_name": "SAVE the EARTH Pillow",
   "image": "Products/p21.png",
   "price": "$21.54",
   "link": "https://www.redbubble.com/i/throw-pillow/SAVE-the-EARTH-by-AzarraAnna/161228747.5X2YF"
   },
   
   {
   "id": 21,
   "product_name": "UNITY Pillow",
   "image": "Products/p22.png",
   "price": "$21.54",
   "link": "https://www.redbubble.com/i/throw-pillow/UNITY-by-AzarraAnna/161297531.5X2YF"
   },
   
   {
   "id": 22,
   "product_name": "SAVE THE PLANET Magnet",
   "image": "Products/p23.png",
   "price": "$8.05",
   "link": "https://www.redbubble.com/i/magnet/SAVE-THE-PLANET-by-AzarraAnna/162035889.TBCTK"
   },
   
   {
   "id": 23,
   "product_name": "EARTH ..LIFE GIVER Magnet",
   "image": "Products/p24.png",
   "price": "$8.05",
   "link": "https://www.redbubble.com/i/magnet/EARTH-LIFE-GIVER-by-AzarraAnna/161229463.TBCTK"
   },
   
   {
   "id": 24,
   "product_name": "ECO FRIENDLY LIFE Mouse Pad",
   "image": "Products/p25.png",
   "price": "$19.94",
   "link": "https://www.redbubble.com/i/mouse-pad/ECO-FRIENDLY-LIFE-by-AzarraAnna/161312678.G1FH6"
   },
   
   {
   "id": 25,
   "product_name": "FREEDOM Mouse Pad",
   "image": "Products/p26.png",
   "price": "$19.94",
   "link": "https://www.redbubble.com/i/mouse-pad/FREEDOM-by-AzarraAnna/161128289.G1FH6"
   },
   
   {
   "id": 26,
   "product_name": "OUR FUTURE..Protect Children Journal",
   "image": "Products/p27.png",
   "price": "$13.81",
   "link": "https://www.redbubble.com/i/notebook/OUR-FUTURE-Protect-Children-by-AzarraAnna/161203990.WX3NH"
   },
   
   {
   "id": 27,
   "product_name": "ECOFRIENDLY Journal",
   "image": "Products/p28.png",
   "price": "$13.81",
   "link": "https://www.redbubble.com/i/notebook/ECOFRIENDLY-by-AzarraAnna/162264141.WX3NH"
   },
   
   
   {
   "id": 28,
   "product_name": "LOVE Drawstring Bag",
   "image": "Products/p29.png",
   "price": "$35.52",
   "link": "https://www.redbubble.com/i/drawstring-bag/LOVE-by-AzarraAnna/161322164.YT4RK"
   },
   
   {
   "id": 29,
   "product_name": "GLOBAL RHYTHMS Drawstring Bag",
   "image": "Products/p30.png",
   "price": "$35.52",
   "link": "https://www.redbubble.com/i/drawstring-bag/GLOBAL-RHYTHMS-by-AzarraAnna/160957421.YT4RK"
   },
   
   {
   "id": 30,
   "product_name": "PEACE Tote Bag",
   "image": "Products/p31.png",
   "price": "$17.39",
   "link": "https://www.redbubble.com/i/tote-bag/PEACE-by-AzarraAnna/161297780.P1QBH"
   },
   
   {
   "id": 31,
   "product_name": "PEACE THROUGH MUSIC Tote Bag",
   "image": "Products/p32.png",
   "price": "$17.39",
   "link": "https://www.redbubble.com/i/tote-bag/PEACE-THROUGH-MUSIC-by-AzarraAnna/161129161.P1QBH"
   },
   
   {
   "id": 32,
   "product_name": "TIME TRAVEL Tote Bag",
   "image": "Products/p33.png",
   "price": "$23.02",
   "link": "https://www.redbubble.com/i/tote-bag/TIME-TRAVEL-by-AzarraAnna/161131355.PJQVX"
   },
   
   {
   "id": 33,
   "product_name": "BEAUTIFUL EARTH Tote Bag",
   "image": "Products/p34.png",
   "price": "$23.02",
   "link": "https://www.redbubble.com/i/tote-bag/BEAUTIFUL-EARTH-by-AzarraAnna/161358171.PJQVX"
   },
   
   {
   "id": 34,
   "product_name": "EARTH ..LIFE GIVER Pin",
   "image": "Products/p35.png",
   "price": "$3.72",
   "link": "https://www.redbubble.com/i/pin/EARTH-LIFE-GIVER-by-AzarraAnna/161229463.NP9QY"
   },
   
   {
   "id": 35,
   "product_name": "GLOBAL RHYTHMS Pin",
   "image": "Products/p36.png",
   "price": "$3.72",
   "link": "https://www.redbubble.com/i/pin/GLOBAL-RHYTHMS-by-AzarraAnna/160957421.NP9QY"
   }
   
   ];
   