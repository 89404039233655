import React from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { SocialIcon } from "react-social-icons";

import "./Footer.css";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

const Footer = () => {
  const is_email=window.matchMedia('(max-width:801px)').matches;
  const is_mobile=window.matchMedia('(max-width:605px)').matches;
  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-start text-muted bg-opacity-90 footer"
    >
      <section className="d-flex  p-1 border-bottom"></section>

      <section className="footer_container">
        <MDBContainer className=" mt-4">
          <MDBRow className="mt-4 ">
            <MDBCol md="2" lg="3" xl="3" sm="12" className=" me-n4 padding-0">
              <h6 className="text-uppercase fw-bold text-center">ECO-Wares</h6>
              <div>
              <p className="text-center">
                <a href="/about" className="text-reset links_footer mb-1 text-center">
                  About Us
                </a>
              </p>

              <p className="text-center">
                <a
                  href="https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna"
                  className="text-reset links_footer"
                >
                  Products
                </a>
              </p>
              </div>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" sm="12" className=" ">
              <h6 className= {is_mobile?"text-uppercase fw-bold text-center mt-3":"text-uppercase fw-bold text-center"}>Useful links</h6>
              <p className="text-center">
                <a
                  href="https://globalpeacemedia.org/"
                  className="text-reset links_footer "
                >
                  Global Peace Media
                </a>
              </p>
              <p className="text-center">
                <a
                  href="https://globalpeacerhythms.com/"
                  className="text-reset links_footer  "
                >
                  Global Peace Rhythm
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="3" xl="2" sm="12" className="mx-auto mb-4">
              
              <h6 className= {is_mobile?"text-uppercase fw-bold text-center mt-3": "text-uppercase fw-bold text-center"}>
                Follow Us
              </h6>
              <div className="text-center pb-4 mt-3">
              
                
                  <SocialIcon
                  className="me-2"
                    url="www.facebook.com"
                    href="https://www.facebook.com/GlobalPeaceMedia/"
                    style={{ height: 30, width: 30 }}
                  />
                

                
                  <SocialIcon
                  className="me-2"
                    url="www.x.com"
                    href="https://twitter.com/globalpeacemed1"
                    style={{ height: 30, width: 30 }}
                  />
                
                
                  <SocialIcon
                  className="me-2"
                    url="www.instagram.com"
                    href="https://www.instagram.com/globalpeacemedia/"
                    style={{ height: 30, width: 30 }}
                  />
                
                
                  <SocialIcon
                  
                    url="www.linkedin.com"
                    href="https://www.linkedin.com/company/74072561/admin/feed/posts/"
                    style={{ height: 30, width: 30 }}
                  />
                
              
              </div>
            </MDBCol>

            <MDBCol
              md="3"
              lg="3"
              xl="4"
              sm="12"
              className="mx-auto mb-md-0 mb-4"
            >
              <h6 className="text-uppercase fw-bold mb-2 text-center">Contact Us</h6>
              
              < div className="text-center">
              
              {is_email? <a href="mailto:createabetterworld22@gmail.com">
              <FaRegEnvelope size={30} style={{color:"black"}}/>
              </a>:<a href="mailto:createabetterworld22@gmail.com" className="email_link">
              <FaRegEnvelope size={20}/>
              &nbsp;createabetterworld22@gmail.com</a>}
              

              
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2024 ECO-Wares. All rights reserved.
        
      </div>
    </MDBFooter>
  );
};
export default Footer;
