import React from 'react'
import Header from '../Header/Header'
import Banner from '../Banner/Banner'
import Products from '../Products/Products'
import Footer from '../Footer/Footer'

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <Products />
      <Footer />
    </>
  )
}

export default Home
