import React from "react";
import Card from "react-bootstrap/Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { categories } from "../data/CategoriesData";
import Button from "react-bootstrap/Button";
import "./Categories.css";
const Categories = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
     
      breakpoint: { max: 1024, min: 480 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div>
        <h1 className="categories_header">Categories</h1>
      </div>
      <div className="carousel-container">
        <div className="carousel">
          <Carousel
            swipeable={false}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={false}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-1-px"
          >
            {categories.map((c, i) => (
              <div className="carousel_image" key={i}>
                <Card
                  className="carousel_container"
                  onClick={() => window.open(`${c.link}`, "_blank")}
                >
                  <Card.Img
                    variant="top"
                    src={c.image}
                    alt="Categories"
                    className="card-image"
                  />
                  <Card.Body className="cardbody">
                    <Card.Title className="card_title">{c.name}</Card.Title>
                  </Card.Body>
                </Card>
              </div>
            ))}

            <Button
              variant="success"
              onClick={() =>
                window.open(
                  "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna",
                  "_blank"
                )
              }
              className="categories_button"
              size="lg"
            >
              Explore All
            </Button>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Categories;
