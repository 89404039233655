import React, { useRef } from "react";
import Header from "../Header/Header";
import ReactPlayer from "react-player";
import { FaYoutube } from "react-icons/fa6";
import { MDBContainer } from "mdb-react-ui-kit";

import "./About.css";
import Footer from "../Footer/Footer";

const VIDEO_PATH = "https://www.youtube.com/watch?v=wYAFfAi8iZE";
const VIDEO_PATH2 = "https://www.youtube.com/watch?v=EuF751fs5Cg";



const About = () => {
  const playerRef = useRef(null);
  const is_tab=window.matchMedia('(max-width:801px)').matches;
  return (
    <>
      <Header />
      {/* <div className="about_background"> */}
      <div className="about_header">
        <h1>About Us</h1>
      </div>
      <div className="about">
        <div>
          <img
            src={process.env.PUBLIC_URL + "image1.png"}
            alt="ecoware_image"
            className={is_tab?"banner_image mb-4":"banner_image"}
          />
        </div>
        <p id="about_para">
          Welcome to ECO-Wares Shop, your one-stop destination for stylish and
          eco-friendly products that make a difference. From trendy T-shirts and
          cozy hoodies to charming mugs and versatile totes, we offer a wide
          range of great-looking items that cater to your lifestyle needs while
          supporting a greater cause. At ECO-Wares Shop, every purchase goes
          beyond the product itself. A significant portion of our sales is
          dedicated to funding essential charities, particularly those focused
          on providing clean drinking water for children in need. By choosing
          ECO-Wares, you’re not only adding unique and high-quality items to
          your collection, but you’re also contributing to life-changing
          projects around the world. Join us in our mission to create a
          sustainable future and make a positive impact on communities in need.
          Shop with us, spread the word, and help make the world a better place,
          one purchase at a time.
        </p>
      </div>

      <p id="player-text">ECO-Wares...Fashion with a Heart</p>

      {is_tab ? (
        <MDBContainer>
          <div className=" ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/wYAFfAi8iZE"
              title="Ecowares video"
              allowfullscreen
            ></iframe>
          </div>
        </MDBContainer>
      ) : (
        <ReactPlayer
          ref={playerRef}
          url={VIDEO_PATH}
          controls={true}
          className=" react_player mx-auto mb-3"
        />
      )}

      {/* <div className="embed-responsive embed-responsive-16by9">
  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
</div> */}

      <div className="earthnow">
        <p id="earthnow_heading">
          ECOWares is part of our EARTHNOW Global Peace Media Project
        </p>
        <p className="earthnow_para mb-6">
          EARTHNOW is an entertaining video/audio series that features People
          who are taking action to create a better world wherever they are and
          encouraging others to build a more positive future for all Beings and
          Lifeforms on Planet Earth. WHAT ACTION WILL YOU TAKE TO CREATE A
          BETTER WORLD?
          <div>
            <span>
              <h4 className="mt-4 pb-4 text-center">
                Available on our YouTube Channel Global Peace Media &nbsp;
                <a href="https://www.youtube.com/@globalpeacemedia9293">
                  <FaYoutube style={{ color: "red", height: 40, width: 40 }} />
                  
                </a>
              </h4>
            </span>
          </div>
        </p>

        </div>

          {is_tab ? (
            <MDBContainer>
              <div className=" ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/EuF751fs5Cg"
                  title="Ecowares video"
                  allowfullscreen
                ></iframe>
              </div>
            </MDBContainer>
          ) : (
            <ReactPlayer
          ref={playerRef}
          url={VIDEO_PATH2}
          controls={true}
          className="react_player mx-auto mb-3"
            />
          )}


          
        

        <div>
          <h4 className="pt-2 mb-5 px-3 text-center" style={{ color: "green" }}>
            EARTHNOW also available on <a href="https://podcasters.spotify.com/pod/dashboard/episode/e2n7qgs"  id="links">SPOTIFY</a> and <a href="https://rumble.com/v5b2zol-earthnow...if-the-butterflies-go-extinct...humans-will-also-become-extinct-.html"  id="links">RUMBLE</a>
          </h4>
          <div className="mb-5 pt-5">
            <p> </p>
          </div>
        </div>
      

      <Footer />
    </>
  );
};

export default About;
