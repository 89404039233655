export const categories = [
  {
    id: 0,
    name: "Pillows and Cushions",
    image: "Categories/1.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-pillows",
  },
  {
    id: 1,
    name: "T-Shirts",
    image: "Categories/2.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-tees",
  },

  {
    id: 2,
    name: "Caps",
    image: "Categories/3.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=all-caps",
  },
  {
    id: 3,
    name: "Coasters",
    image: "Categories/4.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-coasters",
  },

  {
    id: 4,
    name: "Mugs",
    image: "Categories/5.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-mugs",
  },

  {
    id: 5,
    name: "Phone Cases",
    image: "Categories/6.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-phone-cases",
  },

  {
    id: 6,
    name: "Tote Bags",
    image: "Categories/7.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=all-totes",
  },
  {
    id: 7,
    name: "Aprons",
    image: "Categories/8.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-apron",
  },
  {
    id: 8,
    name: "Drawstring Bags",
    image: "Categories/9.png",
    link: "https://www.redbubble.com/people/azarraanna/shop?artistUserName=azarraanna&iaCode=u-bag-drawstring",
  },
];
