import React from "react";
import Image from "react-bootstrap/Image";
import "./Banner.css";

import "bootstrap/dist/css/bootstrap.min.css";
const Banner = () => {
  const is_mobile_screen = window.matchMedia("(max-width:485px)").matches;
  return (
    <>
      {is_mobile_screen ? (
        <div className="mobile_banner">
          <Image src={process.env.PUBLIC_URL + "ecowares_banner2.png"} fluid />
        </div>
      ) : (
        <img
          height="100%"
          width="100%"
          src={process.env.PUBLIC_URL + "ecowares_banner1.png"}
          alt="banner"
        />
      )}
    </>
  );
};

export default Banner;
